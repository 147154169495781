import React, { useEffect, useState } from 'react';
import TranslationList from './TranslationList';
import { GlobalAuthenticatedHttpClient } from '../../helpers/AuthenticatedHttpClient';
import './TranslationsView.css';
import { EditModal } from './MarketSpecificModal/EditModal';
import SearchBox from '../SearchBox/SearchBox';
import { TranslationHelpers } from '../../helpers/TranslationHelpers.js';
import { toast } from 'react-toastify';

export const endPoint = `${process.env.REACT_APP_Endpoint}/api/translations`;

export default function TranslationView() {
  const pageLimit = 20;

  let [translations, setTranslations] = useState([]);
  let [loading, setLoading] = useState(true);
  let [filter, setFilter] = useState("");
  let [selectedTranslation, setSelectedTranslation] = useState(null);
  let [selectedMarket, setSelectedMarket] = useState(null);
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function load() {
      await LoadTranslations();
    }
    load();
  }, [])

  let currentTranslations = []
  if (filter.trim() == "") {
    currentTranslations = translations.map(x => {
      let temp = {
        ...x
      };
      temp.matchType = null;
      return temp;
    }).slice(0, pageLimit);
  }
  else {
    currentTranslations = translations.filter(x => containsText(x, filter)).slice(0, 1000);
  }
  return (
    <div style={{ margin: "0" }}>
      <div style={{ marginTop: "20px" }}>
        <SearchBox onFilterChanged={value => setFilter(value)} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <EditModal
          translation={selectedTranslation}
          market={selectedMarket}
          show={isOpen}
          close={toggleModal}
          update={updateMarketTranslation} />
        <TranslationList
          loading={loading}
          allTranslations={translations}
          translations={currentTranslations}
          onUpdate={updateTranslation}
          showModal={showModal}
          addInlineHelp={addInlineHelp}
        />
      </div>
    </div>
  );

  function containsText(translation, filter) {
    if (filter.trim() == "") {
      translation.matchType = null;
      return true;
    }
    let result = false;
    translation.matchType = "";
    if (translation.sourceText.toLowerCase().includes(filter.toLowerCase().trim())) {
      translation.matchType += "_sourceText_";
      result = true;
    }
    if (translation.translationPair.find(pair => pair.text.toLowerCase().includes(filter.toLowerCase().trim()))) {
      let matchingPairs = translation.translationPair.filter(pair => pair.text.toLowerCase().includes(filter.toLowerCase().trim()));
      for (let i = 0; i < matchingPairs.length; i++) {
        translation.matchType += `_translation${matchingPairs[i].language}_`
      }
      result = true;
    }
    if (TranslationHelpers.doesMarketSpecificTranslationContainText(translation, filter)) {
      let marketSpecificTranslations = TranslationHelpers.getMarketSpecificTranslationsForText(translation, filter);
      for (let i = 0; i < marketSpecificTranslations.length; i++) {
        translation.matchType += `_marketTranslation${marketSpecificTranslations[i]}_`
      }
      result = true;
    }
    if (!result || !filter) {
      translation.matchType = null;
    }
    return result;
  }

  async function LoadTranslations() {
    let response = await GlobalAuthenticatedHttpClient.authorizedGet(endPoint);
    let data = await response.json();
    if (data) {
      setTranslations(data);
      setLoading(false);
    }
  }

  async function updateMarketTranslation(market, sourceText, sourceLanguage, translatedText, targetLanguage) {
    try {
      const body =
      {
        Market: market,
        SourceLanguage: sourceLanguage,
        SourceText: sourceText,
        TargetLanguage: targetLanguage,
        TranslatedText: translatedText
      };
      let response = await GlobalAuthenticatedHttpClient.authorizedPut(`${endPoint}`, JSON.stringify(body));
      let data = await response.json();
      if (data) {
        toast.success("Translation was updated.");
        let copyTranslations = translations;
        const index = copyTranslations.findIndex(t => { return t.sourceText === sourceText });
        copyTranslations[index] = data;

        let copyCurrentTranslations = currentTranslations;
        const currentTranslationsIndex = copyCurrentTranslations.findIndex(t => { return t.sourceText === sourceText });
        copyCurrentTranslations[currentTranslationsIndex] = data;
        setTranslations(copyTranslations);
      }
      else {
        toast.error("Translation could not be updated, see console.");
      }
    } catch (error) {
      toast.error("Translation could not be updated, see console.");
      console.log(error);
    }
  }

  async function updateTranslation(sourceText, sourceLanguage, translatedText, targetLanguage) {
    const body =
    {
      SourceLanguage: sourceLanguage,
      SourceText: sourceText,
      TargetLanguage: targetLanguage,
      TranslatedText: translatedText
    };

    try {
      let response = await GlobalAuthenticatedHttpClient.authorizedPut(`${endPoint}`, JSON.stringify(body));
      let data = await response.json();
      if (data) {
        let copyTranslations = translations;
        const index = copyTranslations.findIndex(t => { return t.sourceText === sourceText });
        copyTranslations[index] = data;
        setTranslations(copyTranslations);
        toast.success("Translation was updated.");
      }
      else {
        toast.error("Translation could not be updated, see console.");
      }
    } catch (error) {
      toast.error("Translation could not be updated, see console.");
      console.log(error);
    }
  }

  async function addInlineHelp(sourceText) {
    const inlineHelpKey = `${sourceText}-inlinehelp`;

    if(translations.findIndex(t => { return t.sourceText === inlineHelpKey }) !== -1){
      toast.error(`Inline help already exists, try to search for key ${inlineHelpKey}.`);
    }
    else{
      const body = {
        SourceLanguage: "EN",
        SourceText: inlineHelpKey,
        TargetLanguages: ["EN"]
      };
      let response = await GlobalAuthenticatedHttpClient.authorizedPost(endPoint, JSON.stringify(body));
      const translation = await response.json();
      translation.highlight=true;
      const translationsCopy = [];
      translations.forEach(trans => {
        trans.highlight = false;
        translationsCopy.push(trans);
      });
      const index = translationsCopy.findIndex(t => { return t.sourceText === sourceText });
      translationsCopy.splice(index + 1, 0, translation);
      setTranslations(translationsCopy);
    }
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function showModal(translation, market) {
    setSelectedTranslation(translation);
    setSelectedMarket(market);
    toggleModal();
  }
}
