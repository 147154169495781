import React, { Component } from 'react';
import { Languages } from '../../../constants/Languages';
import TextareaAutosize from 'react-textarea-autosize';
import './LanguageView.css';

export class LanguageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      initialText: this.props.text,
      dirty: false
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.cancelAddTranslation = this.cancelAddTranslation.bind(this);
    this.saveAddTranslation = this.saveAddTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
  }

  handleTextChange(event) {
    let isDirty = this.state.initialText !== event.target.value;
    if (isDirty) {
      this.setState({
        text: event.target.value,
        dirty: true
      });
    } else {
      let initialText = this.state.initialText;
      this.setState({
        text: initialText,
        dirty: false
      });
    }
    this.props.isDirty(isDirty);
  }

  cancelAddTranslation() {
    let initialText = this.state.initialText;
    this.setState({
      text: initialText,
      dirty: false
    });
    this.props.isDirty(false);
  }

  saveAddTranslation() {
    this.props.save(this.props.language, this.state.text);
    this.setState({
      dirty: false
    });
    this.props.isDirty(false);
  }

  removeTranslation() {
    this.props.removeLanguage(this.props.language);
  }

  render() {
    let language = Languages.find(x => x.key === this.props.language).pretty;
    return (
      <tr>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <span>{language}</span>
            </div>
          </div>
        </td>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <TextareaAutosize value={this.props.baseTranslationText} disabled />
            </div>
          </div>
        </td>
        <td className="arrow"></td>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <TextareaAutosize className="md-textarea form-control" value={this.state.text} onChange={this.handleTextChange} />
            </div>
          </div>
        </td>
        <td>
          {(this.state.dirty) ? (
            <div className="input-group-btn">
              <button type="button" onClick={this.saveAddTranslation} className="btn btn-success btn-sm"><i className="fa fa-check"></i></button>
              &nbsp;<button type="button" onClick={this.cancelAddTranslation} className="btn btn-danger btn-sm"><i className="fa fa-times"></i></button>
            </div>
          ) : <div className="input-group-btn">
              <button type="button" onClick={this.removeTranslation} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i> Remove translation</button>
            </div>}
        </td>
      </tr>
    );
  }
}