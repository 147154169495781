import React, { useEffect, useRef } from 'react';
import './SearchBox.css';

export default function SearchBox(props) {
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    });

    function handleTextChange(event) {
        props.onFilterChanged(event.target.value);
    }

    return (
        <div className="row justify-content-center">
            <div className="col-8">
                <form className="card card-sm" onSubmit={e => { e.preventDefault(); }}>
                    <div className="card-body row no-gutters align-items-center smaller-card">
                        <div className="col-auto">
                            <i className="h4 text-body">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg>
                            </i>
                        </div>
                        <div className="col">
                            <input className="form-control form-control-borderless" type="search" placeholder="Search...." ref={inputRef} onChange={(e) => handleTextChange(e)} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
}