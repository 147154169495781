export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ClientId,
        authority: process.env.REACT_APP_Authority,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage"
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};