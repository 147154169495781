import React, { Component } from 'react';
import { MarketView } from './MarketView'
import './EditModal.css';
import 'flag-icon-css/css/flag-icon.min.css';
import { FlagIconHelper } from '../../../helpers/FlagIconHelper';


export class EditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updates: [],
      dirtyDialogs: {}
    }

    this.persistUpdate = this.persistUpdate.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  persistUpdate(language, text) {
    let update = {
      SourceLanguage: this.props.translation.sourceLanguage,
      SourceText: this.props.translation.sourceText,
      Market: this.props.market.toLowerCase(),
      TargetLanguage: language,
      TranslatedText: text
    }
    let updates = this.state.updates;
    let existingUpdate = updates.find(update => update['TargetLanguage'] === this.state.language);

    if (existingUpdate) {
      updates[updates.indexOf(existingUpdate)] = update;
    } else {
      updates.push(update);
    }
    this.setState({
      updates: updates
    });
  }

  async submitModal() {
    const confirmationMessage = "This dialog contains non-confirmed changes. To save your changes, use the green checkmark button.\n\nSave anyhow and discard these changes?"
    if (Object.keys(this.state.dirtyDialogs).some(key => this.state.dirtyDialogs[key]) && !window.confirm(confirmationMessage)) {
      return;
    }

    for (let i = 0; i < this.state.updates.length; i++) {
      let update = this.state.updates[i];
      await this.props.update(update.Market, update.SourceText, update.SourceLanguage, update.TranslatedText, update.TargetLanguage);
    }

    this.setState({
      updates: []
    });
    this.props.close();
  }

  closeModal() {
    this.setState({
      updates: []
    });
    this.props.close()
  }

  combineChildDirtiness(id, dirty) {
    let dirtyDialogs = { ...this.state.dirtyDialogs }
    dirtyDialogs[id] = dirty;
    this.setState({ dirtyDialogs })
  }


  render() {
    if (!this.props.show) {
      return null;
    }

    let flagIcon = FlagIconHelper.getFlagIconForTwoLetterIsoCode(this.props.translation.sourceLanguage);
    let market = this.props.market.toLowerCase();
    let marketTranslations = this.props.translation.marketSpecificTranslations[market];
    if (!marketTranslations) {
      marketTranslations = [];
    }

    return (
      <div className="modal edit-modal" isOpen={this.props.translation !== null} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit specific translations for Market {market.toUpperCase()} <span className={'flag-icon flag-icon-' + market}></span></h5>
              <button type="button" 
              className="btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              onClick={()=>this.props.closeModal()}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="inputSourceText"><span className={'flag-icon flag-icon-' + flagIcon}></span> Source text</label>
                  <input disabled={true} type="text" id="inputSourceText" className="w-100 form-control" defaultValue={this.props.translation.sourceText} />
                </div>
              </form>
              <hr />
              <MarketView marketTranslationPairs={marketTranslations} baseTranslationPairs={this.props.translation.translationPair} persistUpdate={this.persistUpdate} market={market} isDirty={(id, dirty) => this.combineChildDirtiness(id, dirty)} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={this.submitModal}>Save {this.state.updates.length > 0 ? (<span> {this.state.updates.length} unsaved update(s)</span>) : null}</button>
              <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
