import React from 'react';
import TranslationRow from './TranslationRow';

export default function TranslationList(props) {
    return (
        <div className="table-responsive">
            <table className="table table-striped table-sm">
                <thead>
                    <tr className="table-header-group">
                        <th width="20%" colSpan={2}></th>
                        <th width="60%" colSpan={5} className="table-border-left table-border-right">Base translation (used if no market-specific translation is set)</th>
                        <th width="10%"></th>
                        <th width="10%"></th>
                    </tr>
                    <tr className="table-header-row">
                        <th width="14%">Source text</th>
                        <th width="6%"></th>
                        <th width="12%" className="table-border-left text-center">German</th>
                        <th width="12%" className="text-center">English</th>
                        <th width="12%" className="table-border-right text-center">French</th>
                        <th width="12%" className="table-border-right text-center">Italian</th>
                        <th width="12%" className="table-border-right text-center">Spanish</th>
                        <th width="10%">Market specific translations</th>
                        <th width="10%">Last Time Modified</th>
                    </tr>
                </thead>
                <tbody>
                    {props.loading
                        ? <tr><td colSpan={9}>Loading....</td></tr>
                        : props.translations.map(translation => {
                            let hasInlineHelp= translation.sourceText.includes("-inlinehelp") || props.allTranslations.findIndex(t => { return t.sourceText === `${translation.sourceText}-inlinehelp` }) !== -1;
                            return <TranslationRow 
                            key={translation.sourceText} 
                            translation={translation} 
                            onUpdate={props.onUpdate} 
                            showModal={props.showModal} 
                            addInlineHelp={props.addInlineHelp}
                            hasInlineHelp={hasInlineHelp}
                        />})
                    }
                </tbody>
            </table>
        </div>
    );
}


