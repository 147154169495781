import { Languages } from '../constants/Languages'

export class TranslationHelpers {

  static getMarketSpecificTranslations(translation, language) {
    let marketSpecificTranslations = [];
    Object.keys(translation.marketSpecificTranslations).forEach(market => {
      let translations = translation.marketSpecificTranslations[market].filter(pair => pair.language === language);
      if (translations.length > 0) {
        marketSpecificTranslations.push(market);
      }
    });
    return marketSpecificTranslations;
  }


  static getMarketSpecificTranslationsArray(translation, language) {
    let marketSpecificTranslations = [];
    Object.keys(translation.marketSpecificTranslations).forEach(market => {
      let translations = translation.marketSpecificTranslations[market].filter(pair => pair.language === language);
      if (translations.length > 0) {
        marketSpecificTranslations.push({
          market: market,
          text: translations[0].text
        });
      }
    });
    return marketSpecificTranslations;
  }


  static getMarketSpecificTranslationsForText(translation, text) {
    let marketSpecificTranslations = [];
    Object.keys(translation.marketSpecificTranslations).forEach(market => {
      let translations = translation.marketSpecificTranslations[market].filter(pair => pair.text.toLowerCase().includes(text.toLowerCase().trim()));
      if (translations.length > 0) {
        marketSpecificTranslations.push(market);
      }
    });
    return marketSpecificTranslations;
  }

  static doesMarketSpecificTranslationContainText(translation, text) {
    let result = false;
    Object.keys(translation.marketSpecificTranslations).forEach(market => {
      let translations = translation.marketSpecificTranslations[market].filter(pair => pair.text.toLowerCase().includes(text.toLowerCase().trim()));
      if (translations.length > 0) {
        result = true;
      }
    });
    return result;
  }

  static hasMarketSpecificTranslations(translation) {
    return Object.keys(translation.marketSpecificTranslations).length > 0 && Object.values(translation.marketSpecificTranslations).filter(x => x.length > 0).length > 0;
  }

  static getKeyValuePairForTranslationPairs(translationPairs) {
    let translations = [];
    Languages.map(language => {
      let translationPair = translationPairs.find(x => x.language === language.key.toLowerCase())
      if (translationPair) {
        translations[language.key] = translationPair.text;
      } else {
        translations[language.key] = null;
      }
    });
    return translations;
  }
}