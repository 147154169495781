import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';

const { fetch: originalFetch } = window;

const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    window.fetchAuth = async (...args) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        try {

            let [resource, config] = args;
            const request = {
                scopes: [process.env.REACT_APP_ApiScope],
                account: account,
                authority: process.env.REACT_APP_Authority
            };
            let tokenResponse = await instance.acquireTokenSilent(request);
            const bearer = `Bearer ${tokenResponse.accessToken}`;
            config.headers.Authorization = bearer;
            return await originalFetch(resource, config);
        } catch (e) {
            console.log("Error", e)
        }
    };

    return (
        <>
            {children}
        </>
    );
};


export default RequestInterceptor;