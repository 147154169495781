import 'flag-icon-css/css/flag-icon.min.css';
import './TranslationCell.css';
import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TranslationHelpers } from '../../helpers/TranslationHelpers.js';

export default function TranslationCell(props) {
  let [text, setText] = useState(props.translation.translationPair.filter(pair => pair.language === props.language).map(e => { return e.text })[0]);
  let [hide, setHide] = useState(true);

  let marketSpecificTranslations = TranslationHelpers.getMarketSpecificTranslationsArray(props.translation, props.language);
  return (
    <td className="cell-container">
      <div className="table-block">
        <div className="table-cell full-width">
          {hide
            ? <span className={props.translation.matchType && props.translation.matchType.includes(`_translation${props.language.toLowerCase()}_`) ? "highlighted" : ""}>{text}</span>
            : <TextareaAutosize className="md-textarea form-control" value={text} onChange={e => setText(e.target.value)} />}
        </div>
        <div className="table-cell v-align-top">
          {hide
            ? <button className="btn btn-link btn-sm"><i className="fa fa-edit" onClick={() => setHide(false)}></i></button>
            : <button className="btn btn-link btn-sm"><i className="fa fa-save" onClick={() => saveLanguage()}></i></button>
          }
        </div>
        {(marketSpecificTranslations.length > 0)
          ? (<div><hr className="my-1" />
            <div>
              <small>Market Specific Translations:</small>
              {marketSpecificTranslations.map(marketSpecificTranslation =>
                <div key={marketSpecificTranslation.market} onClick={() => props.onFlagClick(marketSpecificTranslation.market)} className={isMarketHighlighted(marketSpecificTranslation) ? "highlighted table-cell v-align-top d-inline" : "table-cell v-align-top d-inline"}>
                  <span data-toggle="tooltip" title={marketSpecificTranslation.text} className={'market-flag flag-icon flag-icon-' + marketSpecificTranslation.market}></span>
                </div>)}
            </div>
          </div>) : (<div></div>)}
      </div>
    </td>
  );

  function saveLanguage() {
    props.onUpdate(props.translation.sourceText, props.translation.sourceLanguage, text, props.language);
    setHide(true);
  }

  function isMarketHighlighted(marketSpecificTranslation) {
    return props.translation.matchType && props.translation.matchType.includes(`_marketTranslation${marketSpecificTranslation.market.toLowerCase()}_`);
  }
}
