import React from 'react';
import "./NavMenu.css";

export default function NavMenu(props) {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <a className="navbar-brand" href="#"><img alt="logo" src={require('../../static/fiskaltrust.png')} className="logo" /></a>
                    <div className="nav-link">Version 1.1.1</div>
                    <div className="nav-link">
                        <span className="text-muted">{props.name}</span>&nbsp;
                    </div>
                </div>
            </nav>
        </header>
    );
}