import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import TranslationsView from './components/Translation/TranslationsView';
import NavMenu from './components/NavMenu/NavMenu';
import { loginRequest } from "./authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

export default function App(props) {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts);

    if (!isAuthenticated) {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    if (!account) {
        return <div>no account</div>
    }
    return (<>
        <AuthenticatedTemplate>
            <div>
                <NavMenu name={account.name} />
                <div className="wrapper">
                    <div className="content">
                        <Routes>
                            <Route exact path='/' element={<TranslationsView />} />
                            <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                            />
                        </Routes>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <>Trying to login..</>
        </UnauthenticatedTemplate>
    </>
    );
}
