class AuthenticatedHttpClient {

    async authorizedGet(url) {
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        };
        return await window.fetchAuth(url, {
            method: "GET",
            headers: headers
        });
    }

    async authorizedPost(url, body) {
        return await this.authorizedFetch("POST", url, body);
    }

    async authorizedPut(url, body) {
        return await this.authorizedFetch("PUT", url, body);
    }

    async authorizedFetch(method, url, body) {
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        };
        return await window.fetchAuth(url, {
            method: method,
            headers: headers,
            body: body
        });
    }
}

export const GlobalAuthenticatedHttpClient = new AuthenticatedHttpClient();
