import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './EditModal.css';

export class AddTranslationInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddTranslationUi: false,
      text: null,
      language: null,
      baseTranslations: this.props.baseTranslations,
      dirty: false
    };

    this.displayAddTranslationDialog = this.displayAddTranslationDialog.bind(this);
    this.cancelAddTranslation = this.cancelAddTranslation.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.saveAddTranslation = this.saveAddTranslation.bind(this);
  }

  handleTextChange(event) {
    if (this.state.initialText !== event.target.value) {
      this.setState({
        text: event.target.value,
        dirty: true
      });
    } else {
      this.setState({
        text: null,
        dirty: false
      });
    }
  }

  handleLanguageChange(event) {
    let text = this.state.baseTranslations[event.target.value];
    this.setState({
      language: event.target.value,
      text: text,
      baseTranslationText: text
    });
  }

  cancelAddTranslation() {
    if(this.props.isDirty) {
      this.props.isDirty(false);
    }
    this.setState({
      showAddTranslationUi: false,
      text: null,
      baseTranslationText: null,
      language: null
    });
  }

  displayAddTranslationDialog() {
    let defaultLanguage = this.props.languages[0].key;
    let defaultBaseTranslation = this.state.baseTranslations[defaultLanguage];

    if(this.props.isDirty) {
      this.props.isDirty(true);
    }

    this.setState({
      showAddTranslationUi: true,
      language: defaultLanguage,
      baseTranslationText: defaultBaseTranslation,
      text: defaultBaseTranslation
    });
  }

  saveAddTranslation() {
    if (this.state.baseTranslationText === this.state.text) {
      alert("The translations match. No need to add the specific one.");
      return;
    }

    if(this.props.isDirty) {
      this.props.isDirty(false);
    }
    this.props.save(this.state.language, this.state.text)
    this.setState({
      showAddTranslationUi: false,
      text: null,
      baseTranslationText: null,
      language: null
    });
  }

  render() {
    if (this.state.showAddTranslationUi) {
      return (<tr>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <select type="select" value={this.state.language} onChange={this.handleLanguageChange}>
                {this.props.languages.map(language => <option key={"option-" + language.key} value={language.key}>{language.pretty}</option>)}
              </select>
            </div>
          </div>
        </td>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <TextareaAutosize value={this.state.baseTranslationText} disabled />
            </div>
          </div>
        </td>
        <td className="arrow"></td>
        <td className="cell-container">
          <div className="table-block">
            <div className="table-cell full-width">
              <TextareaAutosize className="md-textarea form-control" value={this.state.text} onChange={this.handleTextChange} />
            </div>
          </div>
        </td>
        <td>
          <div className="input-group-btn">
            <button type="button" onClick={this.saveAddTranslation} className="btn btn-success btn-sm"><i className="fa fa-check"></i></button>
            &nbsp;<button type="button" onClick={this.cancelAddTranslation} className="btn btn-danger btn-sm"><i className="fa fa-times"></i></button>
          </div>
        </td>
      </tr>);
    }
    else {
      return (
        <tr>
          <td colSpan="3">
            {(this.props.languages.length > 0
              ? (<button type="button" onClick={this.displayAddTranslationDialog} className="indented btn btn-success">Add translation for Market {this.props.market.toUpperCase()} <span className={'flag-icon flag-icon-' + this.props.market} /></button>)
              : (<div className="alert alert-primary" role="alert">Looking for more languages? Sorry.. currently there are none :)</div>))
            }
          </td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }
}