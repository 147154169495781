import React, { Component } from 'react';
import { Languages } from '../../../constants/Languages'
import { LanguageView } from './LanguageView';
import './EditModal.css';
import { TranslationHelpers } from '../../../helpers/TranslationHelpers.js';
import { AddTranslationInput } from './AddTranslationInput';

export class MarketView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedMarketTranslations: [],
      removedLanguages: []
    };

    this.submitAddTranslation = this.submitAddTranslation.bind(this);
    this.removeLanguage = this.removeLanguage.bind(this);
  }

  submitAddTranslation(language, text) {
    this.props.persistUpdate(language, text);
    const newTranslation = this.state.addedMarketTranslations;
    newTranslation.push({ language: language, text: text });

    this.setState({
      addedMarketTranslations: newTranslation
    });
  }

  removeLanguage(language) {
    let removedLanguages = this.state.removedLanguages;
    removedLanguages.push(language);
    this.setState({
      removedLanguages: removedLanguages
    });
    this.props.persistUpdate(language, '');
  }

  render() {
    let marketTranslationPairs = this.props.marketTranslationPairs;
    let existingTranslationPairs = marketTranslationPairs.concat(this.state.addedMarketTranslations).filter(x => !this.state.removedLanguages.includes(x.language));
    let existingLanguages = existingTranslationPairs.map(x => x.language);
    marketTranslationPairs = marketTranslationPairs.filter(x => !this.state.removedLanguages.includes(x.language));

    let remainingLanguages = Languages.filter((el) => !existingLanguages.includes(el.key));
    let baseTranslations = TranslationHelpers.getKeyValuePairForTranslationPairs(this.props.baseTranslationPairs);

    let addedMarketTranslations = this.state.addedMarketTranslations.filter(x => !this.state.removedLanguages.includes(x.language));

    return (
      <div>
        <div className="w-100">
          <div className="table-responsive">
            <table className="footable table table-striped">
              <thead>
                <tr>
                  <th width="5%">Language</th>
                  <th width="40%">Base translation</th>
                  <th width="5%"><i className="fa fa-arrow-right"></i></th>
                  <th width="40%">Market-specific translation</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {marketTranslationPairs.map((translation, index) =>
                  <LanguageView key={"language-translation-" + this.props.market + index} language={translation.language} baseTranslationText={baseTranslations[translation.language]} text={translation.text} save={this.props.persistUpdate} removeLanguage={this.removeLanguage} isDirty={dirty => this.props.isDirty("language-translation-" + this.props.market + index, dirty)} />)}
                {addedMarketTranslations.map((translation, index) =>
                  <LanguageView key={"language-translation-" + this.props.market + index} language={translation.language} baseTranslationText={baseTranslations[translation.language]} text={translation.text} save={this.props.persistUpdate} removeLanguage={this.removeLanguage} isDirty={dirty => this.props.isDirty("language-translation-" + this.props.market + index, dirty)} />)}
                <AddTranslationInput languages={remainingLanguages} save={this.submitAddTranslation} baseTranslations={baseTranslations} market={this.props.market} isDirty={dirty => this.props.isDirty("add-translation", dirty)} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}