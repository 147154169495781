import 'flag-icon-css/css/flag-icon.min.css';
import './TranslationRow.css';
import React from 'react';
import TranslationCell from './TranslationCell';
import { TranslationHelpers } from '../../helpers/TranslationHelpers.js';
import { Languages } from '../../constants/Languages';
import { Markets } from '../../constants/Markets';
import moment from 'moment';

export default function TranslationRow(props) {
  return (
    <tr className={`${props.translation.highlight && "bg-info"}`}>
      <td>
        {props.translation.createdon != "0001-01-01T00:00:00" && props.translation.modifiedOn == "0001-01-01T00:00:00" ? <span className="badge bg-danger">Autotranslated</span> : null}<br />
        <span className={props.translation.matchType && props.translation.matchType.includes("_sourceText_") ? "highlighted" : ""}>{props.translation.sourceText}</span>
      </td>
      <td>
        {props.translation.sourceText.includes("-inlinehelp")
          ?
          <span className="badge bg-info text-dark">InlineHelp</span>
          :
          <button className={`btn btn-sm btn-secondary ${props.hasInlineHelp && "disabled"}`} onClick={() => props.addInlineHelp(props.translation.sourceText)}><i className="fa fa-plus"></i> Inline help</button>
        }
      </td>
      {Languages.map(language => <TranslationCell key={language.key} translation={props.translation} language={language.key} onUpdate={props.onUpdate} onFlagClick={market => props.showModal(props.translation, market)} />)}
      <td>
        <div>
          {Markets.map(market =>
            TranslationHelpers.hasMarketSpecificTranslations(props.translation)
              ? (<button key={market} id="editEntryBtn" onClick={() => props.showModal(props.translation, market)} type="button" className="btn btn-link btn-sm"><i className="fa fa-pencil"></i><span className={'flag-icon flag-icon-' + market.toLowerCase()}></span></button>)
              : (<button key={market} id="editEntryBtn" onClick={() => props.showModal(props.translation, market)} type="button" className="btn btn-link btn-sm"><span className={'flag-icon flag-icon-' + market.toLowerCase()}></span></button>)
          )}
        </div>
      </td>
      <td>
        {props.translation.modifiedOn == "0001-01-01T00:00:00" ? "Never" : moment(props.translation.modifiedOn).calendar()}
      </td>
    </tr>
  );
}